import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { gsap } from "gsap";
import './preloader.scss';
import logo from '../assets/images/preload.svg';

function LinearProgressWithLabel(props) {
    const el = React.useRef();
    const tl = React.useRef();
    const q = gsap.utils.selector(el);

    React.useEffect(() => {
        tl.current = gsap.timeline()
      .to(q(".square"), {
        opacity: .7
      })
      .to(q(".square"), {
        duration: 1.5, ease: "powerInOut", opacity: 1, repeat: -1, yoyo: true
      });
      }, []);
  return (
    <div className="preload">
      <div className="loader">
        <LinearProgress variant="determinate" {...props} />
      </div>
      <div className="progress">
        <Typography variant="body2" color="">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </div>
    </div>
  );
}

LinearProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function LinearWithValueLabel() {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
    }, 500);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box>
      <div className="anime">
      <div className="load">
      <svg height="100%" fill="none" xmlnsXlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" width="100%" version="1.1" viewBox="0 0 857 151" id="lo">
        <g id="roar">
          <path id="roar-one" stroke="#10e956" stroke-width="5" d="M11.4515+140.139L11.4627+140.151L11.474+140.162C17.0803+145.697+23.8617+148.5+31.6875+148.5L77.7812+148.5C85.6078+148.5+92.3629+145.696+97.9084+140.151C103.512+134.547+106.355+127.762+106.355+119.926L106.355+31.8398C106.355+24.0082+103.515+17.2536+97.9086+11.7128C92.3677+6.1062+85.613+3.26562+77.7812+3.26562L31.6875+3.26562C23.8514+3.26562+17.0665+6.10888+11.4627+11.7127C5.91722+17.2582+3.11328+24.0133+3.11328+31.8398L3.11328+119.926C3.11328+127.752+5.91618+134.533+11.4515+140.139ZM84.2895+25.2818L84.3141+25.307L84.3393+25.3316C86.2012+27.1417+87.0977+29.2631+87.0977+31.8398L87.0977+119.926C87.0977+122.505+86.1988+124.672+84.3143+126.556C82.5098+128.361+80.381+129.242+77.7812+129.242L31.6875+129.242C30.9575+129.242+30.2622+129.17+29.5977+129.028L29.5977+48.2461C29.5977+40.7008+27.335+34.3628+22.6821+29.4548C23.1096+27.8266+23.9024+26.4612+25.0568+25.3068C26.9413+23.4223+29.1088+22.5234+31.6875+22.5234L77.7812+22.5234C80.358+22.5234+82.4794+23.4199+84.2895+25.2818Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-two" stroke="#10e956" stroke-width="5" d="M135.871+26.0735L200.598+78.9245L200.598+146L200.598+148.5L203.098+148.5L217.355+148.5L219.855+148.5L219.855+146L219.855+5.76562L219.855+3.26562L217.355+3.26562L203.098+3.26562L200.598+3.26562L200.598+5.76562L200.598+54.1083L139.054+3.82958L138.364+3.26562L137.473+3.26562L119.113+3.26562L116.613+3.26562L116.613+5.76562L116.613+146L116.613+148.5L119.113+148.5L140.598+148.5L143.098+148.5L143.098+146L143.098+48.4414C143.098+40.7172+140.734+34.2637+135.871+29.3204L135.871+26.0735Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-three" stroke="#10e956" stroke-width="5" d="M315.098+3.26562L312.598+3.26562L312.598+5.76562L312.598+48.1006L280.233+65.0533L255.098+51.9081L255.098+48.2461C255.098+40.5219+252.734+34.0684+247.871+29.1251L247.871+5.76562L247.871+3.26562L245.371+3.26562L231.113+3.26562L228.613+3.26562L228.613+5.76562L228.613+58.1094L228.613+59.6026L229.928+60.3106L233.737+62.3613L233.749+62.3679L233.761+62.3743L270.605+81.6917L270.605+146L270.605+148.5L273.105+148.5L294.59+148.5L297.09+148.5L297.09+146L297.09+105.277C297.09+97.5047+294.734+91.0415+289.863+86.1469L289.863+81.69L330.519+60.3223L331.855+59.6197L331.855+58.1094L331.855+5.76562L331.855+3.26562L329.355+3.26562L315.098+3.26562Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-four" stroke="#10e956" stroke-width="5" d="M448.355+5.76562L448.355+3.26562L445.855+3.26562L373.688+3.26562C365.851+3.26562+359.066+6.10888+353.463+11.7127C347.917+17.2582+345.113+24.0133+345.113+31.8398L345.113+119.926C345.113+127.752+347.916+134.533+353.451+140.139L353.463+140.151L353.474+140.162C359.08+145.697+365.862+148.5+373.688+148.5L445.855+148.5L448.355+148.5L448.355+146L448.355+131.742L448.355+129.242L445.855+129.242L373.688+129.242C373.51+129.242+373.334+129.237+373.16+129.228L373.16+85.5117L433.941+85.5117L436.441+85.5117L436.441+83.0117L436.441+68.7539L436.441+66.2539L433.941+66.2539L373.16+66.2539L373.16+48.2461C373.16+43.8268+372.285+39.8836+370.42+36.5144C368.969+33.738+367.185+30.9826+365.076+28.2473C365.553+27.1241+366.213+26.1507+367.057+25.3068C368.941+23.4223+371.109+22.5234+373.688+22.5234L445.855+22.5234L448.355+22.5234L448.355+20.0234L448.355+5.76562Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-five" stroke="#10e956" stroke-width="5" d="M466.113+146L466.113+148.5L468.613+148.5L540.781+148.5C548.608+148.5+555.363+145.696+560.908+140.151C566.512+134.547+569.355+127.762+569.355+119.926L569.355+31.8398C569.355+24.0082+566.515+17.2536+560.909+11.7128C555.368+6.1062+548.613+3.26562+540.781+3.26562L468.613+3.26562L466.113+3.26562L466.113+5.76562L466.113+146ZM547.289+25.2818L547.314+25.307L547.339+25.3316C549.201+27.1417+550.098+29.2631+550.098+31.8398L550.098+119.926C550.098+122.505+549.199+124.672+547.314+126.556C545.51+128.361+543.381+129.242+540.781+129.242L494.16+129.242L494.16+48.2461C494.16+43.6861+493.248+39.6315+491.303+36.1864C489.663+33.2228+487.683+30.2496+485.371+27.2664L485.371+22.5234L540.781+22.5234C543.358+22.5234+545.479+23.4199+547.289+25.2818Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-six" stroke="#10e956" stroke-width="5" d="M604.652+5.76562L604.652+3.26562L602.152+3.26562L587.895+3.26562L585.395+3.26562L585.395+5.76562L585.395+146L585.395+148.5L587.895+148.5L609.379+148.5L611.879+148.5L611.879+146L611.879+48.2461C611.879+40.5219+609.516+34.0684+604.652+29.1251L604.652+5.76562Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-seven" stroke="#10e956" stroke-width="5" d="M732.355+148.5L734.855+148.5L734.855+146L734.855+94.8281C734.855+87.0002+732.018+80.2483+726.416+74.7089C724.773+73.0176+722.945+71.565+720.938+70.3529C723.188+66.2887+724.309+61.8018+724.309+56.9375L724.309+5.76562L724.309+3.26562L721.809+3.26562L707.648+3.26562L705.148+3.26562L705.148+5.76562L705.148+56.9375C705.148+59.5105+704.254+61.6741+702.378+63.5557C700.501+65.3745+698.33+66.2539+695.734+66.2539L658.098+66.2539L658.098+48.2461C658.098+40.5219+655.734+34.0684+650.871+29.1251L650.871+5.76562L650.871+3.26562L648.371+3.26562L634.113+3.26562L631.613+3.26562L631.613+5.76562L631.613+146L631.613+148.5L634.113+148.5L655.598+148.5L658.098+148.5L658.098+146L658.098+85.5117L706.281+85.5117C708.858+85.5117+710.979+86.4081+712.789+88.27L712.814+88.2953L712.839+88.3198C714.701+90.13+715.598+92.2514+715.598+94.8281L715.598+146L715.598+148.5L718.098+148.5L732.355+148.5Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
          <path id="roar-eight" stroke="#10e956" stroke-width="5" d="M849.959+148.519L852.459+148.519L852.459+146.019L852.459+31.8592C852.459+24.0276+849.619+17.273+844.013+11.7322C838.472+6.12561+831.717+3.28503+823.886+3.28503L777.792+3.28503C769.956+3.28503+763.171+6.12829+757.567+11.7321C752.022+17.2776+749.218+24.0327+749.218+31.8592L749.218+146.019L749.218+148.519L751.718+148.519L773.202+148.519L775.702+148.519L775.702+146.019L775.702+85.5311L833.202+85.5311L833.202+146.019L833.202+148.519L835.702+148.519L849.959+148.519ZM830.394+25.3012L830.419+25.3264L830.444+25.351C832.306+27.1611+833.202+29.2825+833.202+31.8592L833.202+66.2733L775.702+66.2733L775.702+48.2655C775.702+40.7202+773.439+34.3822+768.787+29.4742C769.214+27.846+770.007+26.4806+771.161+25.3262C773.046+23.4417+775.213+22.5428+777.792+22.5428L823.886+22.5428C826.462+22.5428+828.584+23.4393+830.394+25.3012Z" fill="none" stroke-linecap="butt" opacity="1" stroke-linejoin="miter"/>
        </g>
      </svg>
      </div>
      {/* <LinearProgressWithLabel value={progress} /> */}
      </div>
    </Box>
  );
}
